<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";

export default {
  page: {
    title: "Contas a Receber",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Insert, List, Edit },
  data() {
    return {
      currentBanco: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Contas a Receber",
          href: "/financeiro/contasReceber",
          active: true,
        },
      ],
      //tipoMovimento: undefined,
      //tiposMovimento: [],
      banco: undefined,
      T_bancos: [],
      titleBody: "Contass a Receber",
      editando: false,
      inserindo: false,
      hideSearches: false,
      historicos: [],
      clientes: [],
      tiposPagamentos: [],
      centtros: [],
      centro_resultado: [],
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
    this.get_historicos();
    this.get_fornecedores();
    this.get_tipo_pagamento();
    // this.doPost(this.banco)
    this.get_centros();
    this.get_centros_resultado();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async get_tipo_pagamento() {
      this.onLoader();
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.tiposPagamentos = response.data;
          this.offLoader();
        }
      } catch (error) {
        this.makeToast("danger", error.response);
        this.offLoader();
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/receber?empresa_id=" + this.currentEmpresa.id
        );
        //let response = await http.get('http://localhost:8080/api/banco?empresa_id=1');
        // console.log(response1.data);
        if (response.status === 200) {
          this.T_bancos = response.data;
          // console.log(this.T_bancos)
          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.T_bancos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setBanco(banco) {
      this.currentBanco = banco;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldBanco) {
      this.setBanco(oldBanco);
      this.editando = !this.editando;
    },

    async doPost(receber) {
      receber.empresa_id = this.currentEmpresa.id;
      receber.user_id = this.currentUser.id;
      //console.log(receber);
      this.onLoader();

      try {
        let response = await http.post(
          "/receber?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPut(receber) {
      this.onLoader();
      try {
        let response = await http.put(
          "/receber/" + receber.id + "?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(receber) {
      this.onLoader();

      try {
        let response = await http.delete(
          "/receber/" + receber.id + "?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async get_historicos() {
      try {
        await http
          .get("/historicopadrao?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.historicos = res.data ? res.data : null;
            // console.log(this.historicos)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_fornecedores() {
      try {
        await http
          .get("/pessoa?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.clientes = res.data ? res.data : null;
            // console.log(this.fornecedores)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_centros() {
      try {
        await http
          .get("/centrocusto?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.centtros = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_centros_resultado() {
      try {
        await http
          .get("/centroresultado?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.centro_resultado = res.data ? res.data : null;
            //console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    back() {
      this.inserindo = false;
      this.editando = false;
      this.titleBody = "Contas a Receber";
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="col-sm-12 col-md-6"></div> -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button
                  v-if="isHide"
                  class="btn btn-light"
                  @click="searchInputs()"
                >
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Conta
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
            <div class="card-body" v-if="hideSearches">
              <!-- Start Card -->
              <div class="card border border-primary">
                <div class="card-body">
                  <h4 class="card-title">Filtros</h4>
                  <p class="card-title-desc">Informações do Cliente</p>
                  <form class="needs-validation" name="search">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="searchCompras-fromData">Cliente:</label>
                          <div class="row">
                            <div class="col-md-6">
                              <!-- type="datetime-local" -->
                              <input
                                id="codigo_id"
                                type="text"
                                class="form-control"
                                placeholder="Digite o Código"
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                id="nome_id"
                                type="text"
                                class="form-control"
                                placeholder="Digite o Nome"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                    <button class="btn btn-primary" type="button">
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <!-- End Card -->
            </div>
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldRecebe="currentBanco"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :centtros="centtros"
              :centro_resultado="centro_resultado"
              :tiposPagamentos="tiposPagamentos"
              :clientes="clientes"
              :historicos="historicos"
            />
            <Insert
              v-if="inserindo"
              :centtros="centtros"
              :centro_resultado="centro_resultado"
              :tiposPagamentos="tiposPagamentos"
              :clientes="clientes"
              :historicos="historicos"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listBancos="T_bancos"
            :hide="isHide"
            @edit="edit"
            @newTitle="changeHomeTitle"
            @setBanco="setBanco"
            @doDelete="doDelete"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>