<template>
    <b-modal v-model="show1" size="xl" title="Baixar" @hidden="fechar()">
        <div class="card">
            <div class="card-body">
                <div class="form-row form-group" role="group">
                    <div class="col-sm-12 col-md-2">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Conta Para Baixar:</label>
                            <div class="col">
                                <input v-model="baixa.cod_contas_pagar" type="text" class="form-control text-left">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-10">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">&nbsp;</label>
                            <div class="col">
                                <multiselect v-model="baixa.nome_conta_pagar" :options="vetor">

                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row form-group" role="group">
                    <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Vencimento:</label>
                            <div class="col">
                                <input v-model="baixa.vencimento" id="searchCompras-fromData" type="datetime-local"
                                    class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Tipo Pagamento:</label>
                            <div class="col">
                                <multiselect v-model="baixa.tipo_pagamento" :options="pagamentos" label="tiponome">
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row form-group" role="group">
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Pagamento:</label>
                            <div class="col">
                                <input v-model="baixa.pagamento" id="searchCompras-fromData" type="datetime-local"
                                    class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Valor:</label>
                            <div class="col">
                                <input v-model="baixa.valor" type="text" class="form-control text-left" @input="formatInput_valor_info()">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Juros:</label>
                            <div class="col">
                                <input v-model="baixa.juros" type="text" class="form-control text-left" @input="formatInput_juros_info()">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label for="tipo_nome_id" class="col-form-label">Pagar:</label>
                            <div class="col">
                                <input v-model="baixa.pagar" type="text" class="form-control text-left" @input="formatInput_pagar_info()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <label for="tipo_nome_id" class="col-form-label">Pagos:</label>
                    <div class="col-md-12 mt-8">
                        <input v-model="baixa.pagos" type="text" class="form-control text-left" @input="formatInput_pagos_info()">
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
    name: "baixa",
    data() {
        return {
            baixa: {
                // é o codigo e o nome das contas 
                cod_contas_pagar: 1,
                nome_conta_pagar: 'conta',
                //------------------------ 
                vencimento: '2023-08-30T17:21',
                tipo_pagamento: 1,
                pagamento: '2023-08-30T17:21',
                valor: 1.0,
                juros: 1,
                pagar: 12,
                pagos: 1
            },
            vetor: [
                'op1',
                'op2',
                'op3',
            ],
            show1: null,
            pagamentos: [],
        }
    },
    props: {
        show: { type: Boolean },
        tiposPagamentos1: { type: Array }
    },
    created() {
        this.show1 = this.show
        this.pagamentos = this.tiposPagamentos1
    },
    mounted() {
        console.log(this.show)
    },
    components: { Multiselect },
    methods: {
        fechar() {
            this.$emit('fechar_modal')
        },
        formatInput_valor_info() {
            // Remova todos os caracteres não numéricos do valor de entrada
            let numericValue = this.baixa.valor.replace(/\D/g, '');
            // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
            this.baixa.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).replace("R$", " ");
        },
        formatInput_pagar_info() {
            // Remova todos os caracteres não numéricos do valor de entrada
            let numericValue = this.baixa.pagar.replace(/\D/g, '');
            // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
            this.baixa.pagar = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).replace("R$", " ");
        },
        formatInput_juros_info() {
            // Remova todos os caracteres não numéricos do valor de entrada
            let numericValue = this.baixa.juros.replace(/\D/g, '');
            // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
            this.baixa.juros = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).replace("R$", " ");
        },
        formatInput_pagos_info() {
            // Remova todos os caracteres não numéricos do valor de entrada
            let numericValue = this.baixa.pagos.replace(/\D/g, '');
            // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
            this.baixa.pagos = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).replace("R$", " ");
        },
    }
}
</script>
<style></style>