<script>
export default {
  props: {
    banco: { type: Object }
  },
}
</script>

<template>
<div id="banco">
  <p>Nome: {{ banco.cliente_nome }}</p>
  <p>Tipo Pagamento: {{ banco.tipo_pagamento}} </p>
  <p>Valor: {{ banco.valor}} </p>
  <p>Numero: {{ banco.num_documento}} </p>
  <p>Quantidade de Parcelas: {{ banco.qtd_parcelas}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ banco.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ banco.updated_at }}</b-badge></p>
</div>
</template>
